import { createSlice } from "@reduxjs/toolkit";

const initialAgentsState = {
    listLoading: false, // for list call type
    actionsLoading: false, // for action call type
    totalCount: 0,
    entities: null,
    agentForView: undefined,
    lastError: null
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const agentsSlice = createSlice({
    name: "agents",
    initialState: initialAgentsState,
    reducers: {
        // in case of an error
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false; 
            } else {
                state.actionsLoading = false;
            }
        },
        // start a call, setting loading states
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getAgentById
        agentFetched: (state, action) => {
            state.actionsLoading = false;
            state.agentForView = action.payload.agentForView;
            state.error = null;
        },
        // findAgents
        agentsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createAgent
        agentCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.agent);
        },
        // updateAgent
        agentUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.agent.id) {
                    return action.payload.agent;
                }
                return entity;
            });
        },
        // deleteAgent
        agentDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(el => el.id !== action.payload.id);
        },
        // deleteAgents
        agentsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id)
            );
        },
        // agentsUpdateState
        agentsStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        // fetchMyAgents
        myAgentsFetched: (state, action) => {
            const { totalCount, entities, individual_applications } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.individualApplications = individual_applications;
        },
    }
});
