import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useDispatch } from 'react-redux';
import * as auth from "../../app/modules/Auth/_redux/authRedux"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LogoutDialog({ firstName }) {
    const [open, setOpen] = React.useState(true);
    const dispatch = useDispatch();

    // const handleClickOpen = () => {
    //     setOpen(true);
    // }

    const handleClose = () => {
        setOpen(false);
    }

    const signOut = () => {
        dispatch(auth.actions.logout());
        handleClose();
    }

    return (
        <div>
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>Slide in alert dialog</Button> */}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                disableBackdropClick
                disableEscapeKeyDown
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" className="text-danger">{"Session expired"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">Hi {firstName}, your session has just expired! As a security precaution, you are required to sign in again to continue.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => console.log('Learn more')} className="btn btn-clean btn-md btn-wide px-5 m-5">Learn more</Button>
                    <Button onClick={() => signOut()} className="btn btn-danger btn-md btn-wide px-5 m-5">Sign Out</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}