import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
// import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { appConfigs } from "../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";
import ContentLoader from "react-content-loader" // Create skeleton here: https://skeletonreact.com/

export function Brand() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  const { fetchingDataStatus, bank, fetchingSettings, settings } = useSelector(
    (state) => ({
      fetchingDataStatus: state.systemProfile.actionsLoading,
      bank: state.systemProfile.data,
      fetchingSettings: state.systemSettings.actionsLoading,
      settings: state.systemSettings.settings,
    }),
    shallowEqual
  );

  const renderFetchData = () => {
    return fetchingDataStatus ? (
      <div
        className="d-block"
        style={{ overflow: 'hidden' }}
      >
        <ContentLoader
          speed={2}
          width={140}
          height={37}
          viewBox="0 0 140 37"
          backgroundColor="#ededed"
          foregroundColor="#dbdbdb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="140" height="32" />
        </ContentLoader>
      </div>
    ) : (<></>);
  };

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        <Link to="/" className="brand-logo">
          {renderFetchData()}
          {(!fetchingDataStatus && !fetchingSettings) &&
            <img alt="logo" src={(bank?.theme?.avatar) ? ((settings?.hideInstitutionLogo !== 'true') ? appConfigs.uploads_url + 'files/' + bank?.theme?.avatar : layoutProps.headerLogo) : layoutProps.headerLogo} style={{ maxHeight: "40px", maxWidth: "160px" }} />
          }
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            {/* <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")} />
              </span>
            </button> */}
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
