/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_chamasoft/layout";
import AuthRedirect from "./AuthRedirect";
import VerifyUser from "./VerifyUser";

export function AuthPage() {
  return (
    <Switch>
      <ContentRoute path="/auth/redirect" component={AuthRedirect} />
      <ContentRoute path="/auth/verify-user/:token" component={VerifyUser} />
      <Redirect from="/auth" exact={true} to="/auth/redirect" />
      <Redirect to="/auth/redirect" />
    </Switch>
  );
}
