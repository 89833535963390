import React from "react";
import { appConfigs, toAbsoluteUrl } from "../../../_chamasoft/_helpers";
import "../../../_chamasoft/_assets/sass/pages/error/error-5.scss";
import { Link } from "react-router-dom";

export function SetupErrPage() {
  document.title = "Error — " + appConfigs.app_name;

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg4.jpg")})`,
        }}
      >
        <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
          <p className="font-weight-boldest display-4">Administrator intervention required</p>
          <p className="font-size-h3 opacity-80">
            Pardon the interruption, your account has not been<br />
            fully set up. Liaise with your <strong>system administrator</strong><br />
            to have this fixed as soon possible.
          </p>
          <p><Link to="/logout" className="font-weight-bold btn btn-light-primary btn-wide px-5">Okay, that's fine</Link></p>
        </div>
      </div>
    </div>
  );
}
