export const appPermissions = {
    permissions: [
        //==================================
        {
            id: "CS_1",
            name: 'accessToUsers',
            level: 1,
            title: 'Manage Users, Roles & Permissions'
        },
        //----------------------------------
        {
            id: "CS_1a",
            name: 'accessToUsersRead',
            level: 2,
            parentId: "CS_1",
            title: 'Read'
        },
        {
            id: "CS_1b",
            name: 'accessToUsersEdit',
            level: 2,
            parentId: "CS_1",
            title: 'Edit'
        },
        {
            id: "CS_1c",
            name: 'accessToUsersDelete',
            level: 2,
            parentId: "CS_1",
            title: 'Delete'
        },
        //----------------------------------
        //==================================


        //==================================
        {
            id: "CS_2",
            name: 'accessToInstitutionSettings',
            level: 1,
            title: 'Manage Institution'
        },
        //----------------------------------
        {
            id: "CS_2a",
            name: 'accessToInstitutionProfileEdit',
            level: 2,
            parentId: "CS_2",
            title: 'Profile'
        },
        {
            id: "CS_2b",
            name: 'accessToInstitutionBrandingEdit',
            level: 2,
            parentId: "CS_2",
            title: 'Branding'
        },
        {
            id: "CS_2c",
            name: 'accessToGroupsSettingsEdit',
            level: 2,
            parentId: "CS_2",
            title: 'Groups Settings'
        },
        //----------------------------------
        //==================================

        //==================================
        {
            id: "CS_3",
            name: 'accessToData',
            level: 1,
            title: 'Data Access Levels'
        },
        //----------------------------------
        {
            id: "CS_3a",
            name: 'accessToGroupsData',
            level: 2,
            parentId: "CS_3",
            title: 'Groups'
        },
        {
            id: "CS_3b",
            name: 'accessToAgentsData',
            level: 2,
            parentId: "CS_3",
            title: 'Agents'
        },
        {
            id: "CS_3c",
            name: 'accessToBranchesData',
            level: 2,
            parentId: "CS_3",
            title: 'Branches'
        },
        //----------------------------------
        //==================================

        //==================================
        {
            id: "CS_4",
            name: 'accessToReports',
            level: 1,
            title: 'Reports Access Levels'
        },
        //----------------------------------
        {
            id: "CS_4a",
            name: 'accessToGroupsReports',
            level: 2,
            parentId: "CS_4",
            title: 'Groups'
        },
        {
            id: "CS_4b",
            name: 'accessToAgentsReports',
            level: 2,
            parentId: "CS_4",
            title: 'Agents'
        },
        {
            id: "CS_4c",
            name: 'accessToMeetingsReports',
            level: 2,
            parentId: "CS_4",
            title: 'Meetings'
        },
        {
            id: "CS_4d",
            name: 'accessToSavingsReports',
            level: 2,
            parentId: "CS_4",
            title: 'Savings'
        },
        {
            id: "CS_4e",
            name: 'accessToLoansReports',
            level: 2,
            parentId: "CS_4",
            title: 'Loans'
        },
        //----------------------------------
        //==================================

    ]
};

export const hasPermission = (permissions = [], role, permission) => {
    if (role === 'admin') {
        return true;
    } else if (role === 'agent' || role === 'user') {
        let pid = appPermissions.permissions.filter(p => { return p.name === permission }).map(p => p.id);
        if (permissions.includes(pid[0] || '')) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}
