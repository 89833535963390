import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as auth from "../_redux/authRedux";
import { appConfigs } from "../../../../_chamasoft/_helpers";
import { useHistory } from 'react-router-dom';

function AuthRedirect(props) {
  document.title = "Redirecting...";
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );
  const history = useHistory();
  const dispatch = useDispatch();

  if (!user) {
    window.location.replace(appConfigs.auth_url + "/logout?back=" + encodeURI(window.location.protocol + '//' + window.location.host));
    dispatch(auth.actions.logout());
  } else {
    history.push("/auth/verify-user/" + user.redirect_string);
  }
  return (<p style={{ margin: '20px' }}>Redirecting, please wait...</p>);
}

export default injectIntl(connect(null, auth.actions)(AuthRedirect));