import axios from 'axios';
import { appConfigs } from '../../../../_chamasoft/_helpers';

export const LOGIN_URL = 'api/auth/login';
export const REGISTER_URL = 'api/auth/register';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';

export const ME_URL = 'api/me';

export async function login(email, password) {
    try {
        let result = await axios({
            method: 'POST',
            url: LOGIN_URL,
            data: {
                email,
                password,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function register(email, fullname, username, password) {
    try {
        let result = await axios({
            method: 'POST',
            url: REGISTER_URL,
            data: {
                email,
                fullname,
                username,
                password,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function requestPassword(email) {
    try {
        let result = await axios({
            method: 'POST',
            url: REQUEST_PASSWORD_URL,
            data: {
                email,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function verifyUser(redirect_string) {
    try {
        console.log(
            'verifying the user, the url is ' + appConfigs.auth_api_url,
        );
        let result = await axios({
            method: 'POST',
            url: appConfigs.auth_api_url + 'auth/get-user',
            data: {
                redirect_string,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function getUserByToken() {
    try {
        let result = await axios({
            method: 'POST',
            url: appConfigs.auth_api_url + 'users/get-user-by-token',
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function checkinUser(accessToken, profile) {
    try {
        let result = await axios({
            method: 'POST',
            url: appConfigs.auth_api_url + 'users/checkin',
            data: {
                accessToken,
                profile,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}

export async function updatePassword(phone, current_password, new_password) {
    try {
        let result = await axios({
            method: 'POST',
            url: appConfigs.auth_api_url + 'auth/change-password',
            data: {
                phone,
                current_password,
                new_password,
            },
        });
        return result;
    } catch (error) {
        throw new Error(error).message;
    }
}
