import { createSlice } from "@reduxjs/toolkit";

const initialBranchesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    branchForView: undefined,
    lastError: null
};
const initialBranchesOptionsState = {
    listLoading: false,
    actionsLoading: false,
    branches: [],
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const branchesSlice = createSlice({
    name: "branches",
    initialState: initialBranchesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getBranchById
        branchFetched: (state, action) => {
            state.actionsLoading = false;
            state.branchForView = action.payload.branchForView;
            state.error = null;
        },
        // findBranches
        branchesFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        // createBranch
        branchCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.branch);
        },
        // updateBranch
        branchUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.branch.id) {
                    return action.payload.branch;
                }
                return entity;
            });
        },
        // deleteBranch
        branchDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(el => el.id !== action.payload.id);
        },
        // deleteBranches
        branchesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id)
            );
        },
        // branchesUpdateState
        branchesStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        // fetchMyBranches
        myBranchesFetched: (state, action) => {
            const { totalCount, entities, individual_applications } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
            state.individualApplications = individual_applications;
        },
    }
});

export const branchesOptionsSlice = createSlice({
    name: "branchesOptions",
    initialState: initialBranchesOptionsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        branchesOptionsFetched: (state, action) => {
            const { branches } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.branches = branches;
        },
    }
});
